import React, { useEffect, useState } from "react";
import { get } from "../Api/Api";
import { useNavigate } from "react-router-dom";

export default function Section() {
  const [qualities, setQualities] = useState([]);
const  navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getBanner();

        setQualities(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="section">
      <div className="container">
        {qualities.map((el) => (
          <div className="section_item">
            <h1>{el.title}</h1>
            <p>{el.subtitle}</p>
            <button className="btn" onClick={()=>navigate("/about")}>Подробнее</button>
          </div>
        ))}
      </div>
    </div>
  );
}
