import React, { useEffect, useState } from "react";
import but from "./../assets/Image/sal.png";
import { get } from "../Api/Api";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
export default function News() {
  const [about, setQAbout] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getNews();

        setQAbout(response.results);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      <div className="text_title flexs">
        <div>
          <p>Новости </p>
          <h1>Последние события</h1>
        </div>
        <a  onClick={() => navigate("/news")} className="product" href="#">
          Все новости
        </a>
      </div>
      {about.slice(0,3).map((el) => (
        <Fade top duration={1000} delay={300} distance="500px">
          <div className="news">
            <p>{el.created}</p>
            <div className="meeting">
              <h3>{el.title}</h3>
              <p>{el.description}</p>
              <button className="btn border" onClick={() => navigate("/news")}>
                Подробнее
              </button>
            </div>
            <div className="news_item">
              <img className="butss" src={el.image} alt="" />
            </div>
          </div>
        </Fade>
      ))}
    </div>
  );
}
