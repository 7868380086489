import React, { useEffect, useState } from "react";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";

export default function Business() {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getCooperation();

        if (response && Array.isArray(response)) {
          setAbout(response);
        } else {
          console.error("Некорректный формат данных API:", response);
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="business">
        {about.map((item, index) => (
          <div key={index} className="business-section">
            <Fade right duration={1000} delay={300} distance="500px">
              <div className="business-header">
                <p>Бизнес </p>
                <h1>{item.title}</h1>
                <h4>
                  Вместе мы можем изменить привычные подходы к уходу за собой и
                  повысить стандарты красоты, развивая ваш бизнес с помощью
                  проверенных японских инноваций.
                </h4>
              </div>
            </Fade>

            <div className="pro_item">
              {item.details.map((detail) => (
                <Fade right duration={1000} delay={300} distance="500px">
                  <div className="pro">
                    <h3>{detail.title}</h3>
                    <p>{detail.description}</p>
                  </div>
                </Fade>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
