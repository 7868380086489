import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import logo from "./../assets/Image/logo.png";
import images from "./../assets/Image/image.png";
import wellness from "./../assets/Image/wellness.png";
import Health from "../components/Health";
import { get } from "../Api/Api";
import Form from "../components/Form";
export default function AboutUs() {
  const navigate = useNavigate();
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getAboutCompany();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getProduct();
        setProducts(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div id="aboutUs">
        <div className="container">
          <div className="back">
            <HiArrowLeft
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              size={30}
            />
            <img className="logo" src={logo} alt="" />
          </div>
          <h1 className="title-pages">О нас</h1>
        </div>
      </div>
      <div className="container">
        {about.map((el) => (
          <div className="aboutUs">
            <h3>{el.description}</h3>
            <div className="text_about">
              <p>{el.text1}</p>
              <p>{el.text2}</p>
            </div>
          </div>
        ))}
      </div>
      {about.map((el) => (
        <img className="images" src={el.image} alt="" />
      ))}

      {products.map((el) => (
        <div className="container">
          <div className="wellness">
            <h3>
           {el.description}
            </h3>
            <img src={wellness} alt="" />
          </div>
          <div className="call_About">
            <h1>
             {el.title}
            </h1>
            <div className="text_about">
              <p>
              {el.text1}
              </p>
              <p>
              {el.text2}
              </p>
            </div>
          </div>
        </div>
      ))}
      <di v className="lines_about">
        <div className="container">
          <h3>
            Благодаря инновационным японским технологиям CELL были синтезированы
            специальные продукты, оказывающие лечебный и защитный эффект на
            клеточном уровне.
          </h3>
        </div>
      </di>
      <Health />
      <Form/>
    </div>
  );
}
