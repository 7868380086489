import React, { useState, useEffect } from "react";
import "../Styles/Busket.css";
import mbank from "./../assets/Image/mbank.svg";
import elkart from "./../assets/Image/elkart.svg";
import optima from "./../assets/Image/optima_bank_jcGRWzS.svg";
import { useNavigate } from "react-router-dom";
import OrderModal from "../components/OrderModal";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Busket() {
  const [cart, setCart] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(cartItems);
  }, []);

  const updateQuantity = (id, newQuantity) => {
    const updatedCart = cart.map((item) =>
      item.id === id ? { ...item, quantity: newQuantity } : item
    );
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    window.dispatchEvent(new Event('cartUpdated'));
  };

  const removeFromCart = (id) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    window.dispatchEvent(new Event('cartUpdated'));
  };

  const total = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleQuantityChange = (action) => {
    if (action === "increment") {
      setQuantity((prev) => prev + 1);
    } else if (action === "decrement" && quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };
  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div id="busket">
        {cart.length === 0 ? (
          <div className="empty-cart">
            <div className="empty-cart-content">
              <i className="fas fa-shopping-cart"></i>
              <h2>Ваша корзина пуста</h2>
              <p>Добавьте товары для оформления заказа</p>
              <button className="btn" onClick={() => navigate("/all-product")}>
                Перейти к покупкам
              </button>
            </div>
          </div>
        ) : (
          <div className="busket">
            <div className="busket-header">
              <div className="busket-title">Название товара</div>
              <div className="busket-type">Тип</div>
              <div className="busket-quantity">Количество</div>
              <div className="busket-price">Цена</div>
              <button
                className="busket-close"
                onClick={() => navigate("/all-product")}
              >
                ×
              </button>
            </div>

            {cart.map((item) => (
              <div key={item.id} className="busket-item">
                <div className="item-name">{item.name}</div>
                <div className="item-type">{item.type}</div>
                <div className="item-quantity">
                  <button
                    className="quantity-btn color"
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </button>
                  <span className="quantity-display">{item.quantity}</span>
                  <button
                    className="quantity-btn color"
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  >
                    +
                  </button>
                </div>
                <div className="item-price">{item.price * item.quantity} сом</div>
                <button
                  className="remove-item"
                  onClick={() => removeFromCart(item.id)}
                >
                  ×
                </button>
              </div>
            ))}

            <div className="busket-footer">
              <div className="delivery-info">
                Бесплатная доставка от 1500 сом.
              </div>
              <div className="total">
                <span>Итого</span>
                <span>{total} сом</span>
              </div>
              <button
                className="pay-button btn"
                onClick={() => setIsModalOpen(true)}
              >
                Оплатить
              </button>
            </div>
          </div>
        )}
      </div>

      <OrderModal
        isOpen={isModalOpen}
        onClose={closeModal}
        total={total}
        cartItems={cart}
      />
    </div>
  );
}
