import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import call from "./../assets/Image/call.png";
import { get } from "../Api/Api";
import OrderModal from "../components/OrderModal";

export default function Detail() {
  const [about, setQAbout] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await get.getProductDetail(id);
        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const [aboutDetail, setAboutDetailt] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getProducts();

        setAboutDetailt(response.results);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleQuantityChange = (action) => {
    if (action === "increment") {
      setQuantity((prev) => prev + 1);
    } else if (action === "decrement" && quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const addToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];

    const cartItem = {
      id: product.id,
      name: product.title,
      price: product.price,
      quantity: quantity,
      image: product.image,
      type: product.type,
    };

    const existingItemIndex = cartItems.findIndex(
      (item) => item.id === product.id
    );

    if (existingItemIndex !== -1) {
      cartItems[existingItemIndex].quantity += quantity;
    } else {
      cartItems.push(cartItem);
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));

    window.dispatchEvent(new Event("cartUpdated"));

    toast.success("Товар успешно добавлен в корзину!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleBuyClick = () => {
    const cartItem = {
      id: about[0].id,
      name: about[0].title,
      price: about[0].price,
      quantity: quantity,
      image: about[0].image,
      type: about[0].type,
    };
    
    setIsModalOpen(true);
  };

  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="salal">
        <HiArrowLeft
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
          size={30}
        />
      </div>
      <div className="detail"></div>

      {isLoading ? (
        <div>Загрузка...</div>
      ) : (
        about.map((el) => (
          <div>
            <div className="detail_item">
              <div className="butil">
                <img src={el.image} alt="" />
              </div>
              <div className="sikoe_item">
                <div className="sikoe">
                  <h1>{el.title}</h1>
                  <img src={call} alt="" />
                </div>
                <div>
                  <div className="line_bottom">
                    <span className="peremenie">СПОСОБЫ ПРИМЕНЕНИЯ</span>
                    <p className="ago_days">{el.method_of_application}</p>
                  </div>
                  <div className="ageo line_bottom">
                    <p className="peremenie">ИНГРЕДИЕНТЫ</p>
                    <div>
                      <p className="ago_days" dangerouslySetInnerHTML={{ __html: el.ingredients }}></p>
                    </div>
                  </div>
                  <div className="line_bottom flesdd">
                    <p className="peremenie">ТИП</p>
                    <p className="ago_days">{el.type}</p>
                  </div>
                  <div className="quantity-control">
                    <button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange("decrement")}
                    >
                      -
                    </button>
                    <span className="quantity-display">{quantity}</span>
                    <button
                      className="quantity-btn"
                      onClick={() => handleQuantityChange("increment")}
                    >
                      +
                    </button>
                    <h3>{el.price * quantity} сом</h3>
                  </div>
                </div>
                <div className="buttons">
                  <button className="btn borders" onClick={handleBuyClick}>
                    Купить
                  </button>
                  <button
                    className="btn borderred"
                    onClick={() => addToCart(about[0])}
                  >
                    Добавить в корзину
                  </button>
                </div>
              </div>
            </div>
            <div className="details_brain">
              <img className="scrin" src={el.image2} alt="" />
              <div>
                <p className="peremenie">{el.subtitle}</p>
                <div className="umen">
                  {el.advantages.map((advantage, index) => (
                    <p key={index}>{advantage.title}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      <div className="detail"></div>
      <div className="all_detail">
        <p className="sent_too">Смотреть еще </p>
        <p className="all_product" onClick={()=>navigate("/all-product")}>Все продукты</p>
      </div>
      <div className="card_grid">
        {aboutDetail.slice(0, 4).map((el) => (
          <div
            onClick={() => navigate(`/detail/${el.id}`)}
            className="card_product"
          >
            <div className="protein_product">
              <img className="proti" src={el.image} alt="" />
              <div className="hover_overlay">
                <h3 style={{ color: "#fff" }}>{el.title}</h3>
                <p style={{ color: "#fff" }}>{el.volume}</p>
              </div>
            </div>
            <h3>{el.title}</h3>
          </div>
        ))}
      </div>

      <OrderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        total={about[0]?.price * quantity}
        cartItems={[
          {
            name: about[0]?.title,
            price: about[0]?.price,
            quantity: quantity,
            image: about[0]?.image,
          },
        ]}
      />
    </div>
  );
}
