import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import logo from "./../assets/Image/logo.png";
import list from "./../assets/Image/list.png";
import girl from "./../assets/Image/girl.png";
import { get } from "../Api/Api";

export default function News() {
  const navigate = useNavigate();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [aboutDetail, setAboutDetailt] = useState({
    first: [],
    second: [],
    video: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getNewsPage();
        setAboutDetailt(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  const openVideo = () => {
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
  };

  return (
    <div>
      <div id="aboutUs">
        <div className="container">
          <div className="back">
            <HiArrowLeft
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              size={30}
            />
            <img className="logo" src={logo} alt="Logo" />
          </div>
          <h1 className="title-pages">Новости</h1>
        </div>
      </div>

      <div className="container">
        <div className="news-section">
          {aboutDetail.first.map((item) => (
            <div key={item.id} className="fish">
              <p>{new Date(item.created).toLocaleDateString("ru-RU")}</p>
              <h1>{item.title}</h1>
              <h3>читать больше</h3>
            </div>
          ))}
        </div>
        <div className="littsg">
          <div className="list_item">
            {aboutDetail.second.length > 0 && (
              <div className="list">
                <img className="last" src={aboutDetail.second[0].image} alt="" />
                <div className="nevember">
                  <p>{aboutDetail.second[0].created} </p>
                  <h1>{aboutDetail.second[0].title}</h1>
                  <h3>читать больше</h3>
                </div>
              </div>
            )}
          </div>

          <div className="list_item">
            <div className="lasy_items">
              {aboutDetail.second.slice(0, 2).map((item) => (
                <div key={item.id} className="lasy_item">
                  <img className="last_img topxsds" src={item.image} alt="" />
                  <p>{new Date(item.created).toLocaleDateString("ru-RU")}</p>
                  <h2>{item.title}</h2>
                  <h3>читать больше</h3>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
      {aboutDetail.video.slice(0,1).map((el) => (
        <div>
          <div className="video-section">
            <div className="video-thumbnail" onClick={openVideo}>
              <img src={el.image} alt="Video Thumbnail" className="video-image" />
              <button className="play-button">
                <span>▶</span>
              </button>
            </div>
            <div className="container">
              <div className="nevember video-info">
                <p>{el.created}</p>
                <h1>{el.title}</h1>
                <h3>cмотреть</h3>
              </div>
            </div>
          </div>

          {isVideoOpen && (
            <div className="video-modal">
              <div className="video-wrapper">
                <button className="close-button" onClick={closeVideo}>
                  ✖
                </button>

                <video controls autoPlay className="vied">
                  <source src={el.video} type="video/mp4" />
                  Ваш браузер не поддерживает видео.
                </video>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
