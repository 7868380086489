import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Fade } from 'react-reveal';

export default function Form() {
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = {
      first_name: event.target[0].value,
      last_name: event.target[1].value,
      phone_number: event.target[2].value,
      email: event.target[3].value,
    };

    try {
      const response = await fetch("http://109.123.253.64:89/api/v1/request/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data); 
    } catch (error) {
      console.error("Ошибка:", error);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form">
        
          <Fade left duration={1000} delay={300} distance="500px">
          <div>
            <p>Связаться </p>
            <h1>Оставьте запрос</h1>
          </div>
          </Fade>
        
          <Fade right duration={1000} delay={300} distance="500px">
          <div className="sale_item">
            <div className="sale">
              <h2>Получите скидку 10% на первый заказ</h2>
            </div>
            <div>
              <div className="name">
                <input type="text" placeholder="Имя" required />
                <input type="text" placeholder="Фамилия" required />
              </div>
              <input type="tel" placeholder="+996 777 111 797" required pattern="^\+996[0-9]{9}$" />
              <div className="email">
                <input type="email" placeholder="Почта" required />
                <button className="btnsd" type="submit">Отправить <div className="arr"><IoIosArrowForward color="#fff" size={15}/></div></button>
              </div>
            </div>
          </div>
          </Fade>
        </div>
      </form>
    </div>
  );
}