import React, { useEffect, useState } from "react";
import famili from "./../assets/Image/famili.png";
import famil from "./../assets/Image/famil.png";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";
export default function Health() {
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getHealth();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      {about.map((el) => (
        <div>
          <Fade right duration={1000} delay={300} distance="500px">
            <div className="text_title">
              <h1>{el.title}</h1>
            </div>
          </Fade>

          <div className="japones">
            <Fade left duration={1000} delay={300} distance="500px">
              <h3>{el.description}</h3>
            </Fade>
            <div className="live-japon">
              <Fade left duration={1000} delay={300} distance="500px">
                <p>{el.text1}</p>
              </Fade>
              <Fade right duration={1000} delay={300} distance="500px">
                <p>{el.text2}</p>
              </Fade>
            </div>
          </div>

          <div className="famile_item">
            <Fade left duration={1000} delay={300} distance="500px">
              <img className="famili" src={el.image1} alt="" />
            </Fade>
            <Fade right duration={1000} delay={300} distance="500px">
              <img className="famil" src={el.image2} alt="" />
            </Fade>
          </div>
        </div>
      ))}
    </div>
  );
}
