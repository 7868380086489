import React, { useEffect, useState } from "react";
import wellness from "./../assets/Image/wellness.png";
import { FaPlus } from "react-icons/fa6";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";

export default function Product() {
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getProduct();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      {about.map((el) => (
        <div>
          <div className="text_title">
            <p>О продукте</p>
            <h1>
              производства витаминов <span>cell</span>
            </h1>
          </div>
          <div className="wellness">
            <h3>{el.description}</h3>
            <img src={wellness} alt="" />
          </div>
          <div className="lines">
            <Fade left duration={1000} delay={300} distance="500px">
              <div className="cell">
                <FaPlus size={25} color="#fff" />
                <h3>{el.text1}</h3>
              </div>
            </Fade>

            <Fade right duration={1000} delay={300} distance="500px">
              <div className="cell tops">
                <FaPlus size={25} color="#fff" />
                <h3>{el.text2}</h3>
              </div>
            </Fade>
            <Fade bottom duration={1000} delay={300} distance="500px"   >
            <div className="cell lefts">
              <FaPlus size={25} color="#fff" />
              <h3>{el.text3}</h3>
            </div>
            </Fade>
         
          </div>
        </div>
      ))}
    </div>
  );
}
