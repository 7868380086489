import React, { useEffect, useState } from "react";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";
import { GoDotFill } from "react-icons/go";

export default function Opportunities() {
  const [about, setQAbout] = useState([]);
  const [action, setAction] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getCapabilities();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getAction();

        setAction(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="text_title">
          <h1>Ваши возможности</h1>
        </div>
      </div>
      <div className="opportunities">
        <div className="container">
          <div className="opportunity-list">
            {about.map((opportunity, index) => (
              <Fade bottom duration={1000} delay={300} distance="500px">
                <div key={index} className="opportunity-item">
                  <p className="number"><GoDotFill color="#fff" size={13}/>  </p>
                  <h3 className="description">{opportunity.title}</h3>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className=" global">
          <div className="container">
            {action.map((el) => (
              <div className="part">
                <h3>{el.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
