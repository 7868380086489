import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Styles/OrderModal.css';
import mbank from "./../assets/Image/mbank.svg";
import elkart from "./../assets/Image/elkart.svg";
import optima from "./../assets/Image/optima_bank_jcGRWzS.svg";

export default function OrderModal({ isOpen, onClose, total, cartItems }) {
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    comment: '',
    paymentMethod: 'mbank'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const products = cartItems.map(item => ({
      name: item.name,
      price: item.price.toString(),
      count: item.quantity || 1
    }));

    const orderData = {
      name: formData.name,
      phone_number: formData.phone_number,
      email: formData.email,
      address: formData.address,
      products: products,
      price: total.toString(),
      comment: formData.comment
    };

    try {
      const response = await fetch('http://109.123.253.64:89/api/v1/orders/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Order successful:', data);
        
        localStorage.removeItem('cart');
        window.dispatchEvent(new Event('cartUpdated'));
        
        onClose();
        
        toast.success('Заказ успешно оформлен!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {
        console.error('Order failed:', await response.text());
        toast.error('Произошла ошибка при оформлении заказа', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error('Произошла ошибка при оформлении заказа', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>×</button>
          <h2>Оформление заказа</h2>
          <p className="total-amount">Сумма к оплате: {total} сом</p>
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="ФИО"
                required
              />
            </div>
            
            <div className="form-group">
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Номер телефона (+996...)"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
            
            <div className="form-group">
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Адрес доставки"
              />
            </div>

            
            <div className="form-group payment-method">
              <h3>Способ оплаты:</h3>
              <div className="payment-options">
                <label>
                  <img src={mbank} alt="MBank" />
                </label>
                
                <label>
                  <img src={elkart} alt="Elkart" />
                </label>
                
                <label>
                  <img src={optima} alt="Optima Bank" />
                </label>
              </div>
            </div>
            
            <button type="submit" className="submit-button btn">
              Подтвердить заказ
            </button>
          </form>
        </div>
      </div>
    </div>
  );
} 