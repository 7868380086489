import React from 'react'
import Header from './Header'
import About from './About'
import OurMission from './OurMission'
import Product from './Product'
import Health from './Health'
import Opportunities from './Opportunities'
import Catalog from './Catalog'
import Business from './Business'
import News from './News'
import Form from './Form'
import Footer from './Footer'
import Section from './Section'

export default function Main() {
  return (
    <div>
      <Section/>
      <About/>
      <OurMission/>
      <Product/>
      <Health/>
      <Opportunities/>
        <Catalog/>
        <Business/>
        <News/>
        <Form/>
    </div>
  )
}
