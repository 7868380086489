import React, { useEffect, useState } from "react";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";

export default function OurMission() {
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getMission();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      <div className="mission">
        <div className="text_title" style={{ marginTop: "0" }}>
          <p>Наша миссия </p>
        </div>
        {about.map((el) => (

        <Fade right duration={1000} delay={300} distance="500px">
            <h4>{el.text}</h4>
        </Fade>
          ))}

      </div>
    </div>
  );
}
