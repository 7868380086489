import React, { useEffect, useState } from "react";
import logo from "./../assets/Image/logoFooter.png";
import fb from "./../assets/Image/fb.png";
import ins from "./../assets/Image/in.png";
import xx from "./../assets/Image/xx.png";
import tg from "./../assets/Image/tg.png";
import mbank from "./../assets/Image/mbank.svg";
import elkart from "./../assets/Image/elkart.svg";
import optima from "./../assets/Image/optima_bank_jcGRWzS.svg";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaTelegram,
  FaWhatsapp,
  FaYoutube
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const iconComponents = {
  FaFacebook: FaFacebook,
  FaInstagram: FaInstagram,
  FaTwitter: FaTwitter,
  FaLinkedin: FaLinkedin,
  FaTelegram: FaTelegram,
  FaWhatsapp: FaWhatsapp,
  FaYoutube:FaYoutube,
};
export default function Footer() {
  const [about, setQAbout] = useState([]);
  const [contact, setQContact] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getContacts();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getSocial();

        setQContact(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Fade bottom duration={1000} delay={300} distance="500px">
      <div className="container">
        <div className="footer">
          <img className="logo_footer" src={logo} alt="" />
          <div className="contect">
            {about.map((el) => (
              <div className="contactd">
                <h5>Контакты</h5>
                <p>{el.address}</p>
                <a href={`tel:${el.phone_number}`}>{el.phone_number}</a>
              </div>
            ))}
            <div>
              <h5>Полезные ссылки</h5>
              <p style={{cursor: "pointer"}} onClick={() => navigate("/")}>Главная</p>
              <p style={{cursor: "pointer"}} onClick={() => navigate("/about")}> О нас</p>
              <p style={{cursor: "pointer"}} onClick={() => navigate("/all-product")}>Продукция</p>
              <p style={{cursor: "pointer"}} onClick={() => navigate("/news")}>Новости</p>
            </div>
          </div>
          <div>
            <h5>Следите за нами</h5>
            <div className="icons">
              {contact.map((el) => (
                <a href={el.url} target="_blank" rel="noopener noreferrer">
                  {el.name && iconComponents[el.name]
                    ? React.createElement(iconComponents[el.name], {
                        className: "social-icon",
                        style: { fontSize: "24px" },
                      })
                    : null}
                </a>
              ))}
            </div>

            <div className="tio">
              <h5>Оплата через </h5>
              <div className="cash">
                <a href="https://mbank.kg/" target="_blank">
                  <img src={mbank} alt="" />
                </a>
                <a href="https://elcart.kg/" target="_blank">
                  <img src={elkart} alt="" />
                </a>
                <a
                  href="https://optimabank.kg/index.php?lang=ru"
                  target="_blank"
                >
                  <img src={optima} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Maxdev">
          <p>©2024 Developed by Maxdev</p>
          <div className="term">
            <p>Terms Of Services</p>
            <p>Privacy Policy</p>
            <p>Cookie Policy</p>
          </div>
        </div>
      </div>
    </Fade>
  );
}
