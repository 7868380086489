import React, { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import well from "./../assets/Image/wellness.png";
import protein from "./../assets/Image/protein.png";
import { useNavigate } from "react-router-dom";
import { get } from "../Api/Api";
export default function AllProduct() {
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getProducts();

        setQAbout(response.results);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <div id="aboutUs">
        <div className="container">
          <div className="back">
            <HiArrowLeft
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              size={30}
            />
          </div>
          <h1 className="title-pages">Все продукты</h1>
        </div>
      </div>
      <div className="container">
        <img className="well" src={well} alt="" />
        <div className="card_grid">
          {about.map((el) => (
            <div onClick={()=>navigate(`/detail/${el.id}`)} className="card_product">
              <div className="protein_product">
                <img className="proti" src={el.image} alt="" />
                <div className="hover_overlay">
      <h3 style={{color:"#fff"}}>{el.title}</h3>
      <p style={{color:"#fff"}}>{el.volume}</p>
    </div>
              </div>
              <h3>{el.title}</h3>
              <p>{el.volume}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
