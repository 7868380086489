import React, { useEffect, useState } from "react";
import guys from "./../assets/Image/guys.png";
import { get } from "../Api/Api";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
export default function About() {
  const [about, setQAbout] = useState([]);
const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getAbout();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container">
      <div className="text_title">
        <p>О нас</p>
        <h1>здоровью и красоте</h1>
      </div>

      {about.map((el) => (
        <Fade left duration={1000} delay={300} distance="500px">
          <div className="card-item">
            <img className="guys" src={el.image} alt="" />
            <div className="cards">
              <h3>{el.description}</h3>
              <div>
                <p>{el.text}</p>
                <button className="btn" onClick={() => navigate(`/about`)}>Подробнее</button>
              </div>
            </div>
          </div>
        </Fade>
      ))}
    </div>
  );
}
